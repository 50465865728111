<template lang="pug">
.main-wrapper.endpoints-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Endpoint</b>

    Dialog.dialogApagar(header='Remover Endpoint' :visible.sync='dialogApagar' :modal='true')
        p Deseja remover o endpoint <b>{{ dialogApagar_data.nm_endpoint }}</b>?
        .ta-center.my-4
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            Button.p-button-danger(v-else label='Remover' @click='remove()')

    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
        .p-grid.p-fluid
            .p-col-12.p-md-6
                label.form-label Descrição do endpoint:
                .p-inputgroup
                    InputText(
                        placeholder='Descrição do endpoint'
                        @keyup.enter.native='applyFilters()'
                        v-model='filters.ds_descricao'
                    )
            .p-col-12.p-md-6
                label.form-label Descrição do endpoint:
                .p-inputgroup
                    InputText(
                        placeholder='Url do endpoint'
                        @keyup.enter.native='applyFilters()'
                        v-model='filters.ds_url'
                    )
                    Button(icon='jam jam-search' @click='applyFilters()')
                    
    .ta-right.mt-4.mb-4
        Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/endpoints/salvar/0/')")
        
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                    Panel.panel-list.ta-center(:header="props.data.ds_descricao" style='position: relative')
                        .ta-left
                            p <b>Descrição:</b> {{ props.data.ds_descricao }}
                            p <b>Url:</b> {{ props.data.ds_url }}

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Endpoint')
            DataTable(:value="list")
                Column(headerStyle='width: 25%;' field='ds_descricao' header='Descrição')
                    template(#body='props')
                        span(v-tooltip.top="props.data.ds_descricao" style='cursor: pointer;') {{ props.data.ds_descricao }}

                Column(headerStyle='width: 25%;' field='ds_url' header='Url')
                    template(#body='props')
                        span(v-tooltip.top="props.data.ds_url" style='cursor: pointer;') {{ props.data.ds_url }}

                Column(headerStyle='width: 8%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/endpoints/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                        
    
</template>

<style lang="scss">
    .endpoints-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'

    import { Endpoints } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        created () { this.applyFilters() },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                filters: {
                    ds_descricao: '',
                    ds_endpoint: '',
				},
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                return Endpoints.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                Endpoints.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Endpoint removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>